<template>
  <section>
    <v-row class="fill-height">
      <v-col>
        <v-btn color="primary" :to="`/utility_meters/create/${selectedSite.siteId}`">
          <v-icon left>mdi-plus-thick</v-icon>New Utility Meter
        </v-btn>
      </v-col>

      <v-spacer></v-spacer>
      <v-col cols="auto" md="6" sm="6">
        <v-autocomplete
          :items="sites"
          :item-text="(item) => item.name"
          v-model="selectedSite"
          label="Select site"
          return-object
          @change="handleGetMeters"
          solo
        ></v-autocomplete>
      </v-col>
      <v-col cols="auto">
        <v-btn-toggle v-model="toggleView">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on">
                <v-icon>apps</v-icon>
              </v-btn>
            </template>
            <span>Show Card View</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on">
                <v-icon>list_alt</v-icon>
              </v-btn>
            </template>
            <span>Show List View</span>
          </v-tooltip>
        </v-btn-toggle>
      </v-col>
    </v-row>

    <v-row v-if="noDevices">
      <v-card
        class="card-center"
        flat
        tile
        style="background-color: #ecf2f6 !important; font-weight: 800;"
      >
        No {{ typeName }} configured
      </v-card>
    </v-row>
    <confirm-dialog ref="confirmDelete" title="Delete Meter?"></confirm-dialog>
    <v-data-table
      :headers="headers"
      :items="filteredMeters"
      hide-default-footer
      :show-select="$can('delete', 'meters')"
      class="elevation-1"
      v-model="selectedMeters"
      :options="defaultOptions"
      v-if="filteredMeters.length > 0"
    >
      <template v-slot:[`item.deviceName`]="{ item }">
        <td>
          <a :href="`/utility_meters/edit/site/${item.siteId}/meter/${item.monitorId}`">{{
            item.deviceName
          }}</a>
        </td>
      </template>

      <!-- <template v-slot:[`item.upload`]="{ item }">
        <v-btn
          color="primary"
          :to="`/site/${item.siteId}/meter/${item.id}/data/upload`"
        >
          <v-icon left>cloud_upload</v-icon>Upload Data
        </v-btn>
      </template> -->

      <template v-slot:[`item.upload`]="{ item }">
        <td>
          <v-btn
            :to="`/utility_meters/edit/site/${item.siteId}/meter/${item.monitorId}`"
            @click="editItem(item)"
            icon
          >
            <v-icon medium>edit</v-icon>
          </v-btn>
          <v-btn :to="`/site/${item.siteId}/meter/${item.id}/data/upload`" icon>
            <v-icon medium>cloud_upload</v-icon>
          </v-btn>

          <v-btn icon @click="handleMeterDelete(item)">
            <v-icon>mdi-delete-outline</v-icon>
          </v-btn>
        </td>
      </template>

      <template v-slot:top>
        <v-row class="ml-1">
          <v-spacer></v-spacer>
          <v-col cols="auto">
            <v-btn
              v-if="$can('delete', 'meters')"
              tooltip="Delete Selected Meters"
              icon
              @click="handleMeterDelete"
              :disabled="selectedMeters.length === 0"
            >
              <v-icon>mdi-delete-outline</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </v-data-table>
  </section>
</template>
<style>
.card-center {
  display: flex;
  justify-content: center !important;
  align-items: center !important;
  height: 25vh;
  width: 50%;
  margin-left: 25%;
}
</style>

<script>
import { mapGetters, mapActions } from "vuex";
import api from "../_api";
import ConfirmDialog from "@/components/Dialogs/ConfirmDialog";
export default {
  components: {
    "confirm-dialog": ConfirmDialog,
  },
  data() {
    return {
      typeName: "Utility Meters",
      titleIcon: "whatshot",
      sites: [],
      selectedSite: {},
      meters: [],
      selectedMeters: [],
      filteredMeters: [],
      headers: [
        {
          text: "Meter",
          value: "deviceName",
        },
        {
          text: "Display Name",
          value: "friendlyName",
        },
        {
          text: "Actions",
          value: "upload",
        },
      ],
      defaultOptions: {
        // sortBy: ["version"],
        // sortDesc: true,
      },
    };
  },
  async created() {
    this.sites = await api.getSites();

    if (this.defaultSite !== null) {
      this.selectedSite = this.defaultSite;

      this.filteredMeters = await api.getUtilityMeters(
        this.selectedSite.siteId
      );      
    }
  },
  methods: {
    ...mapActions({
      setDefaultSite: "session/setDefaultSite",
    }),
    toggleView() {},

    async handleMeterDelete(item) {
      const self = this;
      const msg =
        "Are you sure you want to delete this meter and its associated data?  Deleting meter cannot be undone.";
      const confirmed = await this.$refs.confirmDelete.confirm(msg);

      if (!confirmed) {
        return;
      }

      if (this.selectedMeters.length > 0) {
        this.selectedMeters.forEach(async function (meter) {
          await api.deleteMeter(meter.monitorId, self.selectedSite.siteId);
          self.filteredMeters = self.filteredMeters.filter(
            (m) => m.monitorId !== meter.monitorId
          );
        });
      } else {
        await api.deleteMeter(item.monitorId, self.selectedSite.siteId);
        self.filteredMeters = self.filteredMeters.filter(
          (m) => m.monitorId !== item.monitorId
        );
      }
      //this.filteredMeters = await api.getMeters(this.selectedSite.siteId);
    },

    upload(item) {
      console.log(item);
    },

    async handleGetMeters() {
      this.setDefaultSite(this.selectedSite);
      this.filteredMeters = await api.getUtilityMeters(this.selectedSite.siteId);
      console.log(this.filteredMeters);
    }
  },
  computed: {
    ...mapGetters({
      defaultSite: "session/defaultSite",
    }),
    noDevices: function () {
      return this.filteredMeters.length === 0;
    },
  },
};
</script>