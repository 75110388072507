const getSites = async () => {
  let result = null
  try {
    result = await window.axios.get('/v1/sites/portfolio')
    let list = result.data.data;
    return list;
  } catch (err) {
    console.error(err);
    return Promise.reject(err)
  }
};

const getMeters = async (siteId) => {
  let result = null
  try {
    result = await window.axios.get(`/v1/utilitymeter/${siteId}`)
    let list = result.data.data;
    return list;
  } catch (err) {
    console.error(err);
    return Promise.reject(err)
  }
};

const getMonitors = async (siteId) => {
  let result = null
  try {
    result = await window.axios.get(`/v1/monitor/${siteId}`)
    let list = result.data.data;
    return list;
  } catch (err) {
    console.error(err);
    return Promise.reject(err)
  }
};

const getUtilityMeters = async (siteId) => {
  let result = null
  try {
    result = await window.axios.get(`/v1/utilitymeter/site/${siteId}`)
    let list = result.data.data;
    return list;
  } catch (err) {
    console.error(err);
    return Promise.reject(err)
  }
};

//temp til new version of core pushed
const getMeter = async (meterId) => {
  let result = null
  try {
    result = await window.axios.get(`/v1/utilitymeter/meter/${meterId}`)
    let meter = result.data.data;

    return meter;
  } catch (err) {
    console.error(err);
    return Promise.reject(err)
  }
};

const getResourceTypes = async () => {
  let result = null
  try {
    result = await window.axios.get('/v1/resourcetype/index/utilitymeter')
    let list = result.data.data;
    return list;
  } catch (err) {
    console.error(err);
    return Promise.reject(err)
  }
};

const getSiteLocations = async (siteId) => {
  let result = null
  try {
    result = await window.axios.get(`/v1/SiteLocation/sitelocations/${siteId}`)
    let list = result.data.data;
    return list;
  } catch (err) {
    console.error(err);
    return Promise.reject(err)
  }
};

const getEndUseTypes = async (resourceTypeId) => {
  let result = null
  try {
    result = await window.axios.get(`/v1/endusetype/resourcetype/${resourceTypeId}`)
    let list = result.data.data;
    return list;
  } catch (err) {
    console.error(err);
    return Promise.reject(err)
  }
};

const createUtilityMeter = async (meter) => {
  let result = null
  try {
    result = await window.axios.post('/v1/utilitymeter', meter)
    let list = result.data.data;
    return list;
  } catch (err) {
    console.error(err);
    return Promise.reject(err)
  }
}

const updateUtilityMeter = async (meter) => {
  let result = null
  try {
    result = await window.axios.put('/v1/utilitymeter/update', meter)
    let list = result.data.data;
    return list;
  } catch (err) {
    console.error(err);
    return Promise.reject(err)
  }
}

const deleteMeter = async (meterId, siteId) => {
  let result = null;

  let deleteUtilityMeterResourceModel = {
    siteId: siteId,
    utilityMeterId: meterId
  }

  let config = {
    data: deleteUtilityMeterResourceModel
  }
  try {
    result = await window.axios.delete(`/v1/utilitymeter/delete/`, config);
    return result.data.data;
  } catch (err) {
    console.error(err);
    return Promise.reject(err);
  }
}

export default {
  getSites,
  getResourceTypes,
  getSiteLocations,
  getEndUseTypes,
  createUtilityMeter,
  updateUtilityMeter,
  getMeters,
  getMonitors,
  getUtilityMeters,
  getMeter,
  deleteMeter
}