var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('v-row',{staticClass:"fill-height"},[_c('v-col',[_c('v-btn',{attrs:{"color":"primary","to":("/utility_meters/create/" + (_vm.selectedSite.siteId))}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus-thick")]),_vm._v("New Utility Meter ")],1)],1),_c('v-spacer'),_c('v-col',{attrs:{"cols":"auto","md":"6","sm":"6"}},[_c('v-autocomplete',{attrs:{"items":_vm.sites,"item-text":function (item) { return item.name; },"label":"Select site","return-object":"","solo":""},on:{"change":_vm.handleGetMeters},model:{value:(_vm.selectedSite),callback:function ($$v) {_vm.selectedSite=$$v},expression:"selectedSite"}})],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn-toggle',{model:{value:(_vm.toggleView),callback:function ($$v) {_vm.toggleView=$$v},expression:"toggleView"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({},on),[_c('v-icon',[_vm._v("apps")])],1)]}}])},[_c('span',[_vm._v("Show Card View")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({},on),[_c('v-icon',[_vm._v("list_alt")])],1)]}}])},[_c('span',[_vm._v("Show List View")])])],1)],1)],1),(_vm.noDevices)?_c('v-row',[_c('v-card',{staticClass:"card-center",staticStyle:{"background-color":"#ecf2f6 !important","font-weight":"800"},attrs:{"flat":"","tile":""}},[_vm._v(" No "+_vm._s(_vm.typeName)+" configured ")])],1):_vm._e(),_c('confirm-dialog',{ref:"confirmDelete",attrs:{"title":"Delete Meter?"}}),(_vm.filteredMeters.length > 0)?_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.filteredMeters,"hide-default-footer":"","show-select":_vm.$can('delete', 'meters'),"options":_vm.defaultOptions},scopedSlots:_vm._u([{key:"item.deviceName",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('a',{attrs:{"href":("/utility_meters/edit/site/" + (item.siteId) + "/meter/" + (item.monitorId))}},[_vm._v(_vm._s(item.deviceName))])])]}},{key:"item.upload",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('v-btn',{attrs:{"to":("/utility_meters/edit/site/" + (item.siteId) + "/meter/" + (item.monitorId)),"icon":""},on:{"click":function($event){return _vm.editItem(item)}}},[_c('v-icon',{attrs:{"medium":""}},[_vm._v("edit")])],1),_c('v-btn',{attrs:{"to":("/site/" + (item.siteId) + "/meter/" + (item.id) + "/data/upload"),"icon":""}},[_c('v-icon',{attrs:{"medium":""}},[_vm._v("cloud_upload")])],1),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.handleMeterDelete(item)}}},[_c('v-icon',[_vm._v("mdi-delete-outline")])],1)],1)]}},{key:"top",fn:function(){return [_c('v-row',{staticClass:"ml-1"},[_c('v-spacer'),_c('v-col',{attrs:{"cols":"auto"}},[(_vm.$can('delete', 'meters'))?_c('v-btn',{attrs:{"tooltip":"Delete Selected Meters","icon":"","disabled":_vm.selectedMeters.length === 0},on:{"click":_vm.handleMeterDelete}},[_c('v-icon',[_vm._v("mdi-delete-outline")])],1):_vm._e()],1)],1)]},proxy:true}],null,true),model:{value:(_vm.selectedMeters),callback:function ($$v) {_vm.selectedMeters=$$v},expression:"selectedMeters"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }